<template>
  <LiefengContent>
    <template v-slot:title>服务清单</template>
    <template v-slot:toolsbarRight>
      <Form :inline="true">
        <FormItem style="margin-bottom: 0">
          <Input
            placeholder="通过服务名称查询"
            search
            enter-button="查询"
            style="width: 330px"
            @on-search="hadlePageSize"
          ></Input>
        </FormItem>
      </Form>
      <Button
        type="error"
        icon="ios-arrow-back"
        @click="$router.push({ path: '/serviceorderorgplatform' })"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :hidePage="true"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/serviceorderorglist')
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h("span", params.index + 1);
          },
        },
        {
          title: "服务名称",
          align: "center",
          key: "itemName",
          minWidth: 200,
        },
        {
          title: "服务简介",
          align: "center",
          key: "introduction",
          minWidth: 200,
        },
        {
          title: "服务价格",
          align: "center",
          key: "price",
          minWidth: 200,
        },
        {
          title: "单位",
          align: "center",
          key: "priceUnit",
          minWidth: 200,
        },
      ],
      tableData: [],
      loading: true,
      page: 1,
      total: 0,
      pageSize: 20,
    };
  },
  methods: {
    async hadlePageSize(val) {
      this.loading = true;
      let res = await this.$get(
        "/gateway/api/homecare/pc/OrderOrgItem/selectOrderByOrgCode",
        {
          keyword: val || "",
          orgCode: "1",
        //   orgCode: parent.vue.loginInfo.userinfo.orgCode,
        }
      );
      this.loading = false;
      if (res.code == 200 && res.dataList) {
        this.tableData = res.dataList;
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 20
              ? res.desc
              : "获取数据失败，请联系管理员处理",
        });
      }
    },
  },
  created() {
    this.hadlePageSize();
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>

<style lang="less" scoped>
</style>